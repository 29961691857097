
export default function i3LoggerApiModule (instance) {
  return {
    async callStartLogEvent (data) {
      const resource = 'i3-logger/call-start-log-event'
      await instance.post(resource, data)
    },
    async callEndLogEvent (data) {
      const resource = 'i3-logger/call-end-log-event'
      await instance.post(resource, data)
    },
    async locationQueryLogEvent (data) {
      const resource = 'i3-logger/location-query-log-event'
      await instance.post(resource, data)
    },
    async locationResponseLogEvent (data) {
      const resource = 'i3-logger/location-response-log-event'
      await instance.post(resource, data)
    },
    async additionalDataQueryLogEvent (data) {
      const resource = 'i3-logger/additional-data-query-log-event'
      await instance.post(resource, data)
    },
    async additionalDataResponseLogEvent (data) {
      const resource = 'i3-logger/additional-data-response-log-event'
      await instance.post(resource, data)
    }
  }
}
