import apiService from '@/modules/api/csp'
import { XMLParser } from 'fast-xml-parser'
import EventDispatcher from '@/modules/event/event-dispatcher.module'
import I3Logger from '@/plugins/i3-logger/I3Logger.plugin'
import LogEventEnum from '@/plugins/i3-logger/enums/i3-logger.enum'

const event = new EventDispatcher()

export default function adrXmlParser () {
  return {
    async parse (resource, type) {
      if (!resource) return
      try {
        const logger = new I3Logger()
        return await apiService.adrApiModule.get(resource).then(async data => {
          const adr = this.parseADR(data?.data, type)
          setTimeout(() => {
            logger.i3LogEvent(window.answeredCallSession, LogEventEnum.AdditionalDataResponseLogEvent, data?.data, type).then(() => {})
          }, 5000)
          return adr
        }).catch(() => {
          event.dispatch(`updated-adr-${type}`, {})
        })
      } catch (e) {
        console.log(e)
      }
    },

    parseADR (data, adrType) {
      const selectedADR = ['Comment', 'SubscriberInfo', 'ServiceInfo', 'DeviceInfo', 'ProviderInfo']

      if (selectedADR.includes(adrType)) {
        const match = new RegExp('EmergencyCallData.' + adrType + '(?=[\\S\\s]{10,8000})[\\S\\s]*EmergencyCallData.' + adrType + '>')
        const ADRMatch = data?.match(match)

        if (ADRMatch && ADRMatch[0]) {
          const parseOptions = { removeNSPrefix: true }
          const parser = new XMLParser(parseOptions)

          const adr = parser.parse(ADRMatch[0])
          event.dispatch(`updated-adr-${adrType}`, { ...adr })
          return adr
        }
      }
    }

  }
}
